<template>
  <div class="comment-container">
    <form ref="form" class="comment-form" @submit="submitComment">
      <div class="columns">
        <div class="left">
          <div>
            <input id="name" type="text" class="form-element" placeholder="Nom" required />
            <span class="floating-label">Nom</span>
          </div>
          <div>
            <input id="email" type="email" class="form-element" placeholder="Email" required />
            <span class="floating-label">Email</span>
          </div>
        </div>
        <div class="right">
          <textarea id="comment" class="form-element" placeholder="Comment" required />
          <span class="floating-label">Commentaire</span>
        </div>
      </div>
      <button ref="submitButton" class="button-submit" type="submit">
        <!-- composant dynamique : l'icône change au clic du bouton -->
        <component :is="currentIcon" />
        Commenter
      </button>
    </form>
    <div class="status" ref="success">
      <IconCircleCheck />
      <p>Le commentaire a été envoyé avec succès !</p>
      <p>Il sera affiché après validation par le webmaster.</p>
    </div>
    <div class="status" ref="error">
      <IconCircleExclamation />
      <p>Oups, il y a eu un problème à l'envoi du commentaire !</p>
      <p>Veuillez contacter le webmaster du site.</p>
      <p><a href="" role="button" @click="backToForm">Cliquez ici</a> pour revoir votre message / réessayer.</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import IconMessage from "@/components/icons/IconMessage.vue";
import IconCircleNotch from "@/components/icons/IconCircleNotch.vue";
import IconCircleExclamation from "@/components/icons/IconCircleExclamation.vue";
import IconCircleCheck from "@/components/icons/IconCircleCheck.vue";

export default {
  name: "CommentForm",
  props: {
    postId: Number,
  },
  data() {
    return {
      currentIcon: "IconMessage",
    };
  },
  components: {
    IconMessage,
    IconCircleNotch,
    IconCircleExclamation,
    IconCircleCheck,
  },
  methods: {
    async submitComment(evt) {
      evt.preventDefault();

      this.$refs.submitButton.disabled = true;

      this.currentIcon = "IconCircleNotch";

      const [name, email, comment] = evt.target.elements;

      const data = {
        post: this.postId,
        author_name: name.value,
        author_email: email.value,
        content: comment.value,
      };

      const url = this.backendURL + "/wp-json/wp/v2/comments";
      await axios
        .post(url, data)
        .then(() => {
          evt.target.classList.add("hidden");
          this.$refs.success.classList.add("show");
        })
        .catch((error) => {
          evt.target.classList.add("hidden");
          this.$refs.error.classList.add("show");
          console.error("Erreur POST commentaire : ", error);
        });
    },
    backToForm(evt) {
      evt.preventDefault();
      this.$refs.submitButton.disabled = false;
      this.currentIcon = "IconMessage";
      this.$refs.error.classList.remove("show");
      this.$refs.form.classList.remove("hidden");
    },
  },
};
</script>


<style lang="scss">
.comment-container {
  position: relative;
}

.comment-form {
  margin-top: 2em;
  transition: all 0.5s linear;

  .columns {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2em;

    .left > :not(:first-of-type) {
      margin-top: 1em;
    }
  }

  div {
    position: relative;
  }

  .right {
    width: 30em;
    textarea.form-element {
      height: 7.15em;
      width: 100%;
      resize: vertical;
    }
  }

  .form-element {
    padding: 0.9em 10px;
    border: 1px solid #e4e4e4;
    border-radius: 0;
    background-color: var(--container-bg-color);

    &:focus {
      outline: 0;
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  .left {
    width: 15em;
    .form-element {
      width: 100%;
    }
  }

  .form-element:focus ~ .floating-label,
  .form-element:not(:placeholder-shown) ~ .floating-label {
    top: 4px;
    font-size: 11px;
  }

  // Need a placeholder for the floating label to work correctly
  // But make it transparent to hide it
  .form-element:not(#website)::placeholder {
    color: transparent;
  }

  .floating-label {
    font-style: normal;
    font-size: 1em;
    color: var(--form-floating-label-color);
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 0.9em;
    transition: 0.2s ease all;
  }

  .button-submit {
    background-color: #a8dbef;
    border: 2px solid transparent;
    border-radius: 3px;
    color: #fff;
    font-size: 1em;
    font-weight: 500;
    margin-top: 1em;
    padding: 10px 30px;
    cursor: pointer;

    &:hover,
    &:disabled {
      background-color: transparent;
      border-color: #b8c2d6;
      color: #b8c2d6;
    }

    &:disabled {
      cursor: wait;
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.comment-container .status {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  transition: opacity 0.5s linear 0.5s;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
</style>