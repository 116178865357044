<template>
  <div class="container">
    <section id="contact">
      <ImgThumbnail v-if="sizesArray" id="contact-img" :sizesArray="sizesArray" sizes="270px" :width="imgWidth" :height="imgHeight" />
      <p>Vous pouvez me contacter à l'aide du formulaire ci-dessous.</p>
      <p class="small">Tous les champs sauf le téléphone sont nécessaires.</p>
      <div class="contact-container">
        <form ref="form" class="contact-form" @submit="submitMessage">
          <div class="columns">
            <div class="left">
              <div>
                <input id="name" type="text" class="form-element" placeholder="Nom" required />
                <span class="floating-label">Nom</span>
              </div>
              <div>
                <input id="email" type="email" class="form-element" placeholder="Email" required />
                <span class="floating-label">Email</span>
              </div>
              <div>
                <input id="phone" type="tel" class="form-element" placeholder="Téléphone" />
                <span class="floating-label">Téléphone (optionnel)</span>
              </div>
            </div>
            <div class="right">
              <div>
                <input id="subject" type="text" class="form-element" placeholder="Sujet" required />
                <span class="floating-label">Sujet</span>
              </div>
              <div>
                <textarea id="message" class="form-element" placeholder="Message" required />
                <span class="floating-label">Message</span>
              </div>
            </div>
          </div>
          <button ref="submitButton" class="button-submit" type="submit">
            <!-- composant dynamique : l'icône change au clic du bouton -->
            <component :is="currentIcon" />
            Envoyer
          </button>
        </form>
        <div class="input-error">
          <IconCircleExclamation :isBig="false" />
          <p ref="inputError">Erreur de saisie !</p>
        </div>
        <div class="status" ref="success">
          <IconCircleCheck />
          <p>Le message a été envoyé avec succès !</p>
        </div>
        <div class="status" ref="error">
          <IconCircleExclamation />
          <p>Oups, il y a eu un problème à l'envoi du message !</p>
          <p><a href="" role="button" @click="backToForm">Cliquez ici</a> pour revoir votre message / réessayer.</p>
          <p ref="email"></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import ImgThumbnail from "@/components/ImgThumbnail.vue";
import IconPaperPlane from "@/components/icons/IconPaperPlane.vue";
import IconCircleNotch from "@/components/icons/IconCircleNotch.vue";
import IconCircleExclamation from "@/components/icons/IconCircleExclamation.vue";
import IconCircleCheck from "@/components/icons/IconCircleCheck.vue";

export default {
  name: "Contact",
  components: {
    ImgThumbnail,
    IconPaperPlane,
    IconCircleNotch,
    IconCircleExclamation,
    IconCircleCheck,
  },
  async created() {
    this.$store.commit("setTitle", { title: "Contact" });

    // Récupère l'image "contact" par son id
    const url = this.backendURL + "/wp-json/wp/v2/media/" + this.contactImgId;

    const response = await axios.get(url);
    this.sizesArray = Object.values(response.data.media_details.sizes);

    this.imgHeight = response.data.media_details.height;
    this.imgWidth = response.data.media_details.width;
    
  },
  data() {
    return {
      sizesArray: null,
      currentIcon: "IconPaperPlane",
    };
  },
  methods: {
    async submitMessage(evt) {
      evt.preventDefault();

      const [name, email, phone, subject, message] = evt.target.elements;

      let data = {
        author_name: name.value,
        author_email: email.value,
        author_phone: phone.value,
        subject: subject.value,
        content: message.value,
        imgHeight: "",
        imgWidth: "",
      };

      if (!this.isInputValid(data)) return;

      this.$refs.submitButton.disabled = true;

      this.currentIcon = "IconCircleNotch";

      const url = this.backendURL + "/wp-json/custom/v1/contact";
      await axios
        .post(url, data)
        .then(() => {
          evt.target.classList.add("hidden");
          this.$refs.success.classList.add("show");
        })
        .catch((error) => {
          this.showError(evt.target, error);
        });
    },
    isInputValid(input) {
      let errorMsg = "";
      let nbErrors = 0;

      if (input.author_name.length < 3) {
        errorMsg += "Le nom fait moins de 3 caractères.<br>";
        nbErrors++;
      }
      if (!input.author_email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        errorMsg += "L'email n'est pas valide.<br>";
        nbErrors++;
      }
      // Le tel est optionnel : on le checke seulement si rempli
      if (input.author_phone != "") {
        // Check if it starts with "+" or a number, and we also accept "-", "." or spaces between the numbers
        if (!input.author_phone.match(/^\+?[0-9]+([-. ]?[0-9]+)+$/)) {
          errorMsg += "Le numéro de téléphone est invalide. Rappel : ce numéro est optionnel, vous pouvez le supprimer.<br>";
          nbErrors++;
        } else {
          // Check if there are enough digits in the phone number
          // Extract only the digits inside the phone number
          const phoneOnlyNumbers = input.author_phone.replace(/[+-. ]/, "");

          if (phoneOnlyNumbers.length < 7) {
            errorMsg = "Le numéro de téléphone contient moins de 7 chiffres. Rappel : ce numéro est optionnel, vous pouvez le supprimer.<br>";
            nbErrors++;
          }
        }
      }
      if (input.subject.length < 5) {
        errorMsg += "Le sujet fait moins de 5 caractères.<br>";
        nbErrors++;
      }
      if (input.content.length < 15) {
        errorMsg += "Le message fait moins de 15 caractères.<br>";
        nbErrors++;
      }

      if (nbErrors > 0) {
        if (nbErrors == 1) {
          errorMsg = "Erreur : " + errorMsg;
        } else {
          errorMsg = 'Les erreurs suivantes ont été détectées :<br><span class="red">' + errorMsg + "</span>";
        }
        this.$refs.inputError.innerHTML = errorMsg;
        this.$refs.inputError.parentNode.classList.add("show");
        return false;
      } else {
        this.$refs.inputError.parentNode.classList.remove("show");
        return true;
      }
    },
    showError(elt, error) {
      elt.classList.add("hidden");

      // On n'affiche l'email qu'en cas d'erreur pour que les robots ne le voient pas
      // Et seulement si ce n'est pas du spam

      if (error.response && error.response.data.code != "is_spam") {
        this.$refs.email.innerHTML = "En cas d'échecs répétés, veuillez me contacter via <a href=\"mailto:" + this.telemacEmail.join("") + '">' + this.telemacEmail.join("") + "</a>";
      }

      this.$refs.error.classList.add("show");
      console.error("Erreur POST commentaire : ", error);
      console.log(error.response);
    },
    backToForm(evt) {
      evt.preventDefault();
      this.$refs.submitButton.disabled = false;
      this.currentIcon = "IconPaperPlane";
      this.$refs.error.classList.remove("show");
      this.$refs.form.classList.remove("hidden");
    },
  },
};
</script>


<style lang="scss">
.contact-container {
  position: relative;
}

#contact {
  
  padding-bottom: 0;
  
  .small {
    font-size: 0.8em;
  }

  #contact-img {
    border-radius: 9999px;
    width: 15em;
    margin-bottom: 1em;
  }
}

.contact-form {
  margin-top: 2em;
  transition: all 0.5s linear;

  .columns {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2em;

    .left > :not(:first-of-type),
    .right > :not(:first-of-type) {
      margin-top: 1em;
    }
  }

  div {
    position: relative;
  }

  .right {
    width: 30em;

    input {
      width: 100%;
    }

    textarea {
      height: 11em;
      width: 100%;
      resize: vertical;
    }
  }

  .form-element {
    padding: 0.9em 10px;
    border: 1px solid #e4e4e4;
    border-radius: 0;
    background-color: var(--container-bg-color);

    &:focus {
      outline: 0;
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  .left {
    width: 15em;
    .form-element {
      width: 100%;
    }
  }

  .form-element:focus ~ .floating-label,
  .form-element:not(:placeholder-shown) ~ .floating-label {
    top: 4px;
    font-size: 11px;
  }

  // Need a placeholder for the floating label to work correctly
  // But make it transparent to hide it
  .form-element:not(#website)::placeholder {
    color: transparent;
  }

  .floating-label {
    font-style: normal;
    font-size: 1em;
    color: var(--form-floating-label-color);
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 0.9em;
    transition: 0.2s ease all;
  }

  .button-submit {
    background-color: #a8dbef;
    border: 2px solid transparent;
    border-radius: 3px;
    color: #fff;
    font-size: 1em;
    font-weight: 500;
    margin-top: 1em;
    padding: 10px 30px;
    cursor: pointer;

    &:hover,
    &:disabled {
      background-color: transparent;
      border-color: #a8dbef;
      color: #a8dbef;
    }

    &:disabled {
      cursor: wait;
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.contact-container .status {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  transition: opacity 0.5s linear 0.5s;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  a:visited {
    color: var(--link-color);
  }
}

.contact-container .input-error {
  opacity: 0;
  //visibility: hidden;
  transition: opacity 0.5s linear;
  margin-top: 1em;
  min-height: 7em;

  &.show {
    opacity: 1;
    //visibility: visible;
  }

  p {
    display: inline-block;
    margin-left: 0.5em;

    .red {
      color: rgb(227, 30, 30);
    }
  }

  svg {
    margin-top: 1em;
  }
}
</style>