import { createStore } from 'vuex'

export default createStore({
  state: {
    // Position du bas de la bannière titre pour définir le début d'affichage de la top nav mobile
    bannerBottom: "",
    title: "",
    titleTag: "h1",
    titleHTMLTag: "", // Contenu de la balise <title>
  },
  mutations: {
    setbannerBottom (state, bannerBottom) {
      state.bannerBottom = bannerBottom;
    },
    setTitle (state, payload) {
      state.title = payload.title;
      state.titleTag = payload.titleTag ?? "h1";
      state.titleHTMLTag = payload.titleHTMLTag ?? payload.title
    }
  },
  actions: {
  },
  modules: {
  }
})
