<template>
  <a class="button" :href="href" role="button">
    <span>{{ label }}</span>
    <div class="icon">
      <span>>></span>

    </div>
  </a>
</template>

<script>
export default {
  name: "ButtonAnim",
  props: {
    label: String,
    href: String
  },
};
</script>

<style lang="scss">
//TODO A nettoyer : plein de trucs inutiles : <i>, classes fa, success...

// Variables
$speed: "0.25s";
$transition: all #{$speed} cubic-bezier(0.31, -0.105, 0.43, 1.4);

[data-dark-theme="false"] {
  .button--blue {
    background-color: #a8dbef;

    > span:after {
      background-color: #72b6d1; // Color of the separator
    }
  }
  .button--orange {
    background-color: #f3c491;

    > span:after {
      background-color: #db8f3d; // Color of the separator
    }
  }
}

[data-dark-theme="true"] {
  .button span {
    color: #e3e3e6; // color of the text and the arrow
  }

  .button--blue {
    background-color: #00739d;

    > span:after {
      background-color: #006488; // Color of the separator
    }
  }
  .button--orange {
    background-color: #c78b4b;

    > span:after {
      background-color: #bd7222; // Color of the separator
    }
  }
}

/* Source of the button : https://codepen.io/Shtam3x/pen/Bevpxd */
.button {
  display: inline-block;
  background-color: #b0ccec;
  width: 300px;
  height: 60px;
  line-height: 60px;
  margin: 0.5em 40px;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  //box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  transition: $transition;

  span {
    color: #ffffff; // color of the text and the arrow
  }

  > span,
  .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
  }

  > span {
    width: 72%;
    line-height: inherit;
    font-size: 22px;
    //text-transform: uppercase;
    left: 0;
    transition: $transition;

    &:after {
      content: "";
      background-color: #8db7e7;
      width: 2px;
      height: 70%;
      position: absolute;
      top: 15%;
      right: -1px;
    }
  }

  .icon {
    width: 28%;
    right: 0;
    transition: $transition;

    span {
      font-size: 25px;
      vertical-align: middle;
      transition: $transition, height #{$speed} ease;
    }
  }

  &.success,
  &:hover {
    > span {
      left: -72%;
      opacity: 0;
    }

    .icon {
      width: 100%;

      span {
        font-size: 45px;
      }
    }
  }

  &:hover {
    opacity: 0.9;

  }

  &:active {
    opacity: 1;
  }

  @media (max-width: 460px) {
    width: 90%;
    margin: 0.5em 0;
  }
}
</style>