<template>
  <img :srcset="srcset" :sizes="sizes" alt="" />
</template>

<script>
export default {
  name: "ImgThumbnail",
  props: {
    sizesArray: {
        type: Array,
        default: null
    },
    sizes: String,
  },
  data() {
    return {
      srcset: "",
    };
  },
  created() {
    for (const size of this.sizesArray) {
      this.srcset += size.source_url + " " + size.width + "w, ";
    }
    // Enlève les deux derniers caractères en trop (virgule, espace)
    this.srcset = this.srcset.slice(0, -2);
  },
};
</script>
