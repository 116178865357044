<template>
  <div class="container long">
    <section id="blog">
      <div id="category-list">
        <p>Filtrer une catégorie :</p>
        <div>
          <a href="#" role="button" :id="'category-' + category.id" class="category-item" @click="setFilteredCategory" v-for="category in categoryList" :key="category">
            {{ category.name }}
          </a>
        </div>
      </div>

      <ArticleList v-on:reset-pager="resetPager" :categories="filteredCategory" :page="page" />

      <div id="pager" v-if="nbPages > 1">
        <a v-for="n in nbPages" :key="n" @click="setPage" :class="{ active: n == 1 }" href="" role="button">{{ n }}</a>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import ArticleList from "@/components/ArticleList.vue";

export default {
  name: "Blog",
  components: {
    ArticleList,
  },
  async created() {
    this.$store.commit("setTitle", { title: "Le Blog" });

    // Fetch des catégories, exclusion de l'id 1 (uncategorized)
    const url = this.backendURL + "/wp-json/wp/v2/categories?exclude=1";

    const response = await axios.get(url);
    this.categoryList = response.data;
  },

  data() {
    return {
      categoryList: [],
      nbPages: Number,
      page: 1,
      filteredCategory: ",",
    };
  },
  methods: {
    setFilteredCategory(evt) {
      evt.preventDefault();
      let categoryElement = evt.currentTarget;

      this.filteredCategory = categoryElement.id.split("-")[1];

      if (categoryElement.classList.contains("active")) {
        this.filteredCategory = ",";
        categoryElement.classList.remove("active");
      }
      // Si ce n'étais pas la catégorie active, on enlève l'autre catégorie active avant de la déclarer active
      else {
        // Test de toutes les catégories pour voir si elles étaient actives
        for (const categoryElementSibling of categoryElement.parentNode.childNodes) {
          if (categoryElementSibling.classList && categoryElementSibling.classList.contains("category-item")) {
            categoryElementSibling.classList.remove("active");
          }
        }
        categoryElement.classList.add("active");
      }
    },
    resetPager(nbPages) {
      this.page = 1;
      this.nbPages = nbPages;
    },
    setPage(evt) {
      evt.preventDefault();
      let oldPage = this.page;
      let pageNumberElt = evt.currentTarget;

      // Si la page n'était pas active, on change de page
      if (!pageNumberElt.classList.contains("active")) {
        // On devrait viser oldPage - 1 mais Vue créée des text nodes au début et à la fin du v-for
        pageNumberElt.parentNode.childNodes[oldPage].classList.remove("active");
        this.page = Number(evt.currentTarget.textContent);
        pageNumberElt.classList.add("active");
      }

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>


<style lang="scss">
#category-list {
  background-color: var(--category-list-bg-color);
  border-radius: 10px;
  //height: 3em;
  padding: 1em 0;
  margin-bottom: 1em;

  p {
    display: inline-block;
    margin: 0;
  }

  div {
    display: inline-block;

    a {
      display: inline-block;
      background-color: var(--category-list-item-color);
      margin: 0.5em 0.5em 0;
      padding: 0.3em 0.4em;
      border-radius: 10px;
      border: 2px solid transparent;
      color: $text-color;
      transition: all 1s ease;

      &:hover,
      &.active {
        background-color: var(--category-list-bg-color);
        border: 2px solid var(--category-list-item-color);
      }
    }
  }
}

#pager {
  margin: 3em auto;
  background-color: var(--category-list-bg-color);
  border-radius: 1.8em;
  //height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  width: fit-content;
  padding: 0.3em;

  a {
    padding: 0.9em 0;
    display: inline-block;
    height: 3em;
    width: 3em;
    color: var(--text-color);
    border: 0.1em solid transparent;
    border-radius: 1.5em;
    transition: border-color 1s ease;

    &.active {
      background-color: var(--category-list-item-color);
    }
    &:hover {
      border: 0.1em solid var(--category-list-item-color);
    }
  }
}
</style>