<template>
  <Page slug="about" />
</template>

<script>
// @ is an alias to /src
import Page from "@/components/Page.vue";

export default {
  name: "About",
  components: {
    Page,
  },
  created() {
    let description = document.createElement('meta');
    description.name = "description";
    description.content = "Damien Herraud. Développeur web indépendant, spécialiste WordPress (PHP) et VueJS."
    document.querySelector('head link').insertAdjacentElement('beforebegin', description);
  }
};
</script>

<style lang="scss">
#about {
  
  .is-style-rounded img {
    border-radius: 9999px;
  }
  
  .wp-block-social-links {
    display: flex;
    justify-content: center;
    gap: 0.5em;
    padding-left: 0;
    width: auto;
    list-style: none;
  }

  .wp-block-social-link {
    border-radius: 9999px;
    line-height: 0;
    margin-top: 0;
    padding: 0.25em;

    &:hover {
      opacity: 0.6;
    }

  }

  .wp-social-link-linkedin {
    background-color: #0d66c2;

    svg {
      fill: #ffffff;
    }
  }

  .wp-social-link-facebook {
    background-color: #1778f2;

    svg {
      fill: #ffffff;
    }
  }

  h2:first-of-type {
    margin-top: 1em;
    margin-bottom: 2em;
  }

}
</style>