<template>
  <Page slug="business" class="long" />

  <!-- <div id="presentation__links">
          <ButtonAnim label="En savoir plus" class="button--blue" />
          <ButtonAnim label="Qui suis-je ?" class="button--orange" />
        </div> -->
</template>

<script>
// @ is an alias to /src
import Page from "@/components/Page.vue";

export default {
  name: "Business",
  components: {
    Page,
  },
};
</script>

<style lang="scss">
.projects {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 2rem;

  .project {
    display: block;
    color: var(--text-color);
    width: 350px;
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid var(--container-bg-color);
    background-color: var(--article-card-bg-color-2);
    transition: border-color 1s ease;

          &:hover {
        border: 2px solid #dfdfdf;

        img {
          opacity: 0.8;
        }
      }

    .img-partner {
      height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &#customgame {
        background-color: #1d0074;
      }

      &#naturobus {
        background-color: #cbefd6;
      }

      img {
        transition: opacity 1s ease;
      }

      &:not(.full-width) {
        padding: 0 15px;
      }

      &.full-width img {
        display: block;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    p {
      font-size: 17px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media (max-width: 992px) {
  .projects {
    flex-direction: column;
    margin: 0 auto;
    max-width: 450px;

    .project {
      width: auto;
      max-width: 450px;

      .img-partner {
        height: 300px;

        &:not(.full-width) img {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
