<template>
    <div class="container">
      <section id="error">
        <p>Cette page n'existe pas !</p>
      </section>
    </div>
</template>

<script>

export default {
  name: "Error404",
  created() {
    this.$store.commit("setTitle", {title: "Erreur 404 !"});
  }
};
</script>


<style lang="scss">

</style>