import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Business from '../views/Business.vue'
import Blog from '../views/Blog.vue'
import Article from '../views/Article.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Error404 from '../views/Error404.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/business',
    name: 'Activité',
    component: Business,
    meta: { inMenu: true }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    meta: { inMenu: true }
  },
  {
    path: '/article/:slug',
    name: 'Article',
    component: Article
  },
  {
    path: '/about',
    name: 'Bio',
    component: About,
    meta: { inMenu: true }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { inMenu: true }
  },
  {
    path: '/404',
    name: '404',
    component: Error404
  },
  {
    // Si la route n'est pas connue
    // Sort de l'app Vue pour être traité en tant que code 404 par Apache
    path: '/:pathMatch(.*)*',
    beforeEnter() { window.location.href = "/404" }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
