<template>
  <div id="article-list">
    <a :href="'/article/' + article.slug" v-for="article in articleList" :key="article">
      <article>
        <h3 v-html="article.title.rendered" />
        <p>
          le
          <time :datetime="article.date">{{ getFormattedDate(article.date) }}</time>
        </p>
        <!-- The container is needed to have a white background for hover opacity in dark mode -->
        <div class="img-container">
          <ImgThumbnail :sizesArray="getThumbnailSizes(article)" sizes="(max-width: 992px) 77vw, (max-width: 1300px) 36vw, 450px" />
        </div>
        <div v-html="article.excerpt.rendered" />
      </article>
    </a>
  </div>
</template>

<script>
import axios from "axios";
import ImgThumbnail from "@/components/ImgThumbnail.vue";

export default {
  name: "ArticleList",
  components: {
    ImgThumbnail,
  },
  props: {
    categories: {
      type: String,
      default: ",",
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 8,
    },
  },
  created() {
    this.updateArticleList();
  },
  data() {
    return {
      articleList: [],
      currentPage: 1,
    };
  },
  watch: {
    categories: "updateArticleList",
    page: "updatePage",
  },
  methods: {
    getThumbnailSizes(article) {
      let imgSizes = article._embedded && Object.values(article._embedded["wp:featuredmedia"]["0"].media_details.sizes);

      return imgSizes ?? [];
    },
    getFormattedDate(wpDate) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedDate = new Date(wpDate).toLocaleDateString("fr-FR", options);

      return formattedDate;
    },
    async updateArticleList() {
      // Lorsqu'on change de catégorie, on repasse en page 1
      this.currentPage = 1;

      const response = await this.getArticleListResponse();
      this.$emit("reset-pager", Number(response.headers["x-wp-totalpages"]));
      this.articleList = response.data;
    },
    async updatePage() {
      // updateArticleList() fait changer page qui est watched. Alors on vérifie qu'on n'appelle pas updatePage une deuxième fois pour la même requête
      if (this.currentPage != this.page) {
        this.currentPage = this.page;
        const response = await this.getArticleListResponse();
        this.articleList = response.data;
      }
    },
    async getArticleListResponse() {
      const url = this.backendURL + "/wp-json/wp/v2/posts?_embed=wp:featuredmedia&per_page=" + this.perPage + "&categories=" + this.categories + "&page=" + this.currentPage;

      const response = await axios.get(url);

      return response;
    },
  },
};
</script>

<style lang="scss">
//$article-link-width: 45%;
$article-padding: 2rem;
/* $thumbnail-width: ($container-width - 2 * $padding-side-section) *
  math.div($article-link-width, 100%) - 2 * $article-padding; */

#article-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  a {
    display: block;
    width: 45%;
    //background-color: #f7f7f7;
    border-radius: 10px;
    margin-top: 2rem;
    border: 2px solid transparent;
    color: $text-color;
    transition: border-color 1s ease;

    &:nth-of-type(4n + 1),
    &:nth-of-type(4n) {
      background-color: var(--article-card-bg-color-1);
    }

    &:nth-of-type(4n + 2),
    &:nth-of-type(4n + 3) {
      background-color: var(--article-card-bg-color-2);
    }

    .img-container {
      background-color: #ffffff; // white background for hover opacity
      line-height: 0;
    }

    img {
      transition: opacity 1s ease;
    }

    &:hover {
      border: 2px solid #dfdfdf;

      img {
        opacity: 0.8;
      }
    }
  }

  article {
    padding: 2rem;

    img {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover; // by fixing aspect ratio, this will crop image
    }

    > div {
      font-size: 1rem;
      text-align: left;
    }
  }
}

@media (max-width: 1300px) {
  #article-list {
    justify-content: space-between;

    a {
      width: 48%;
    }
  }
}
@media (max-width: 992px) {
  #article-list {
    display: block;

    a {
      width: 100%;

      &:nth-of-type(2n + 1) {
        background-color: var(--article-card-bg-color-1);
      }

      &:nth-of-type(2n) {
        background-color: var(--article-card-bg-color-2);
      }

      .img-container {
        //max-height: 400px;
        margin: 0 auto;
        img {
          max-height: 350px;
        }
      }
    }
  }
}
@media (max-width: 460px) {
  article {
    padding: 1rem;
  }
}
</style>