<template>
  <header>
    <!-- Topbar uniquement sur mobile -->
    <nav ref="topbar" class="topbar" :class="{ show: showTopBar }">
      <a :href="baseURL">
        <picture>
          <source srcset="../assets/img/titre_lightmode.webp" type="image/webp" />
          <source srcset="../assets/img/titre_lightmode.png" type="image/png" />
          <img src="../assets/img/titre_lightmode.png" width="2003" height="390" alt="Telemac, lien vers la page d'accueil" />
        </picture>
      </a>
      <div class="hamburger" :class="{ active: isActive }" @click="mobileMenuToggle">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
    </nav>
    <nav class="navbar">
      <a :href="baseURL">
        <div class="nav-logo-container">
          <div class="nav-logo">
            <picture>
              <source srcset="../assets/img/logo.webp, ../assets/img/logo_x2.webp 2x, ../assets/img/logo_x3.webp 3x, ../assets/img/logo_x4.webp 4x" type="image/webp" />
              <source srcset="../assets/img/logo.jpg, ../assets/img/logo_x2.jpg 2x, ../assets/img/logo_x3.jpg 3x, ../assets/img/logo_x4.jpg 4x" type="image/jpeg" />
              <img src="../assets/img/logo_x2.jpg" width="100" height="100" alt="Logo Telemac, lien vers la page d'accueil" />
            </picture>
          </div>
          <div class="nav-title">
            <picture>
              <source srcset="../assets/img/titre_lightmode.webp" type="image/webp" />
              <source srcset="../assets/img/titre_lightmode.png" type="image/png" />
              <img src="../assets/img/titre_lightmode.png" width="2003" height="390" alt="Telemac, lien vers la page d'accueil" />
            </picture>
          </div>
        </div>
      </a>
      <ul class="nav-menu" :class="{ active: isActive }">
        <li class="nav-item" v-for="route in getMenuRoutes" :key="route">
          <a :href="route.path" class="nav-link" :class="{ current: route.meta.isCurrent }" @click="mobileMenuToggle">{{ route.name }}</a>
        </li>
      </ul>
      <div class="hamburger" :class="{ active: isActive }" @click="mobileMenuToggle">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  mounted() {
    window.addEventListener("scroll", this.scrollEventThrottling);
  },
  data() {
    return {
      showTopBar: false,
      isActive: false,
      lastKnownScrollPosition: 0,
      scrollPosAtMenuOpening: 0,
      ticking: false,
      baseURL: process.env.BASE_URL,
    };
  },
  computed: {
    getMenuRoutes() {
      let routes = [];

      for (const route of this.$router.getRoutes()) {
        if (route.meta.inMenu) {
          // Blog sera la route courante pour les articles (route Article)
          route.meta.isCurrent = route.name == this.$route.name || (route.name == "Blog" && this.$route.name == "Article");
          routes.push(route);
        }
      }
      return routes;
    },
  },
  methods: {
    mobileMenuToggle() {
      // Toggle the isActive boolean
      this.isActive = !this.isActive;

      // Si on vient d'ouvrir le menu, on enregistre la scroll position
      if (this.isActive) {
        this.scrollPosAtMenuOpening = window.scrollY;
      }
    },
    scrollEventThrottling() {
      this.lastKnownScrollPosition = window.scrollY;

      if (!this.ticking) {
        setTimeout(() => {
          if (this.isActive) this.closeMobileMenuOnScroll();
          this.toggleTopNavOnScroll();
          this.ticking = false;
        }, 300);

        this.ticking = true;
      }
    },
    // Si l'utilisateur scrolle de plus de 150px depuis l'ouverture du menu, on le ferme
    closeMobileMenuOnScroll() {
      if (Math.abs(this.lastKnownScrollPosition - this.scrollPosAtMenuOpening) > 150) {
        this.mobileMenuToggle();
      }
    },
    toggleTopNavOnScroll() {
      if (this.lastKnownScrollPosition > this.$store.state.bannerBottom) {
        this.showTopBar = true;
      } else {
        this.showTopBar = false;
      }
    },
  },
};
</script>

<style lang="scss">
header {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  /* border-bottom: 1px solid #e2e8f0; */ /* Removed because ugly. useful? */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.topbar {
  display: none;
}

.navbar li {
  list-style: none;
}

// https://dev.to/devggaurav/let-s-build-a-responsive-navbar-and-hamburger-menu-using-html-css-and-javascript-4gci
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;

  a {
    color: $text-color;
  }
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--text-color);
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
}

.nav-item {
  margin-left: 5rem;
}

.nav-link {
  font-size: 1.4rem;
  font-weight: 400;
  //color: #475569;
  padding-bottom: 0.4rem;
  border-bottom: 2px solid transparent;
  transition: border-color 1s ease;
}

.nav-link.current {
  border-color: inherit;
}

.nav-link:hover {
  border-color: inherit;
}

.nav-title {
  max-width: 15rem;
}

.nav-logo-container {
  display: flex;
  align-items: center;
}

.nav-logo {
  width: 100px;
  min-width: 75px;
  margin-right: 1rem;
  border: 1px solid $text-color;
  line-height: 0;
  border-radius: 50%;
  overflow: hidden;
  transition: transform 1s ease;
  will-change: transform;
}

.navbar a:hover {
  .nav-logo {
    transform: rotate(-32deg) scale(1.2);
  }
}

[data-dark-theme="true"] {
  .nav-title img {
    content: url("../assets/img/titre_darkmode.png");
  }
  .topbar img {
    content: url("../assets/img/titre_darkmode.png");
  }
}

@media only screen and (max-width: 992px) {
  .nav-item {
    margin-left: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    position: fixed;
    left: 0;
    z-index: 3;
    padding: 8px 10px;
    // background-color: var(--article-card-bg-color-1);

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background-color: $body-bg-color;
      backdrop-filter: blur(3px);
    }

    transform: translate(0, -100%);
    transition: transform 0.5s;

    &.show {
      transform: translate(0, 0);
    }

    a {
      height: 100%;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .navbar {
    width: 90%;
    padding: 1rem 0;
    margin: 0 auto;
  }

  .nav-menu {
    position: fixed;
    right: -50%;
    top: 0;
    flex-direction: column;
    background-color: #fff;
    width: 50%;
    border-radius: 10px 0 0 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    z-index: 3;
    margin: 4.7rem 0;
  }

  .nav-menu.active {
    right: 0;
    //opacity: 0.9;
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background-color: rgba(247, 247, 247, 0.9);
      backdrop-filter: blur(3px);
    }
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    margin-left: 2rem;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  [data-dark-theme="true"] {
    .nav-menu {
      background-color: #434349;
    }
    .nav-menu.active {
      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        background-color: rgba(82, 82, 90, 0.85);
      }
    }
  }
}

@media (max-width: 460px) {
  .navbar {
    width: 95%;
  }
  .nav-logo {
    width: 80px;
  }

  .nav-title {
    max-width: 13rem;
  }
}

@media (max-width: 390px) {
  .nav-logo {
    margin-right: 0.5rem;
  }
}
</style>