<template>

    <div class="container long">
      <section id="presentation">
        <p>
          L'entreprise <strong>Telemac</strong> répond à vos besoins de création
          de sites Web ou, plus largement, de développement Web.
        </p>
        <p>
          En tant que développeur indépendant, je peux supporter vos projets
          efficacement (sans intermédiaire !) tout en assurant le suivi de votre
          site avec professionnalisme.
        </p>
        <p>
          J'utilise des technologies à la fois éprouvées et actuelles avec un
          coeur de site généralement basé sur WordPress, que j'affectionne pour
          sa robustesse, ses fonctionnalités et sa sécurité.
        </p>
        <div id="presentation__links">
          <ButtonAnim label="En savoir plus" href="/business" class="button--blue" />
          <ButtonAnim label="Qui suis-je ?" href="/about" class="button--orange" />
        </div>
      </section>
      <div class="separator"></div>
      <section id="blog_home">
        <h2>Le blog</h2>
        <p>
          Quitte à avoir un site web, autant s'en servir pour partager des infos
          ou des astuces (plus ou moins) utiles !
        </p>
        <ArticleList />
      </section>
    </div>

</template>

<script>
// @ is an alias to /src
import ButtonAnim from "@/components/ButtonAnim.vue";
import ArticleList from "@/components/ArticleList.vue";

export default {
  name: "Home",
  components: {
    ButtonAnim,
    ArticleList,
  },
  created() {
    this.$store.commit("setTitle", {title: "Développement web / Création de sites"});
  }
};
</script>

<style lang="scss">

#presentation > p {
  //padding: 0 7rem;
  width: 80%;
  margin: 1em auto;
}

</style>
