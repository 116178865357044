<template>
  <div ref="title" class="title" @click="bounceText" @animationend="handleAnimationEnd">
    <BannerImg />
    <!-- On affiche une deuxième image de bannière à droite si l'écran est très large ! -->
    <BannerImg class="wide-only" />
    <component :is="titleTag" class="title__text title__text--entrance" v-html="title"></component>
  </div>
</template>

<script>

import BannerImg from "@/components/BannerImg.vue";

export default {
  name: "PageTitle",
  components: {
    BannerImg,
  },
  computed: {
    title() {
      return this.$store.state.title;
    },
    titleTag() {
      return this.$store.state.titleTag;
    },
  },
  mounted() {
    // On enregistre la position du bas de la bannière titre
    // On vérifie si l'event window.load a été émis. En fonction des pages c'est le cas ou non !
    if (document.readyState === "complete") {
      this.commitBannerBottom();
    } else {
      window.onload = () => {
        this.commitBannerBottom();
      };
    }
  },
  updated() {
    // balise HTML <title>
    if (window.location.pathname == "/") {
      document.title = "Telemac - Développement web / Création de sites";
    } else {
      // la balise title n'aime pas l'apostrophe (différente du single quote)
      document.title = this.$store.state.titleHTMLTag.replace("&#8217;", "’") + " - Telemac";
    }
  },
  data() {
    return {
      entranceAnimationEnded: false,
    };
  },
  methods: {
    bounceText(evt) {
      if (this.entranceAnimationEnded) {
        evt.currentTarget.lastChild.classList.remove("title__text--entrance");
        evt.currentTarget.lastChild.classList.add("title__text--bounce");
      }
    },
    handleAnimationEnd(evt) {
      if (evt.animationName == "entrance-text") {
        this.entranceAnimationEnded = true;
      } else if (evt.animationName == "bounce-text") {
        evt.currentTarget.lastChild.classList.remove("title__text--bounce");
      }
    },
    commitBannerBottom() {
      let titleBannerBottom = this.$refs.title.getBoundingClientRect().bottom + window.pageYOffset;
      this.$store.commit("setbannerBottom", titleBannerBottom);
    },
  },
};
</script>

<style lang="scss">
.title {
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // background: #000;
  //margin-top: 2rem;

  /* Here we use a small hack to add a transparent black background on top of the background image  
  ** It creates a sort of dark filter for more readability
  ** https://www.digitalocean.com/community/tutorials/how-to-change-a-css-background-images-opacity-fr
  */

  /*   &::before {
    background-image: url("../assets/bg-image.png");
    background-size: cover;
    content: " ";
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    opacity: 0.6;
    animation: entrance-bg 6s;

    @keyframes entrance-bg {
      from {
        opacity: 1;
      }
    }
  } */

  picture {
    width: 100%;
    height: 100%;
  }

  .wide-only {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title__text {
  position: absolute;
  top: 1rem;
  z-index: 2;
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  //padding: 2rem;
  //vertical-align: middle;
  padding: 0 0.8em;
  margin: 0;
  will-change: transform;
  -webkit-text-stroke: 1px #0094ca;
}

.title__text--entrance {
  animation: entrance-text 2s;
  transform: scale(1);

  @keyframes entrance-text {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      transform: scale(1);
    }
  }
}

.title__text--bounce {
  animation: bounce-text 1s;
  transform-origin: center bottom;

  @keyframes bounce-text {
    from,
    20%,
    53%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -30px, 0) scaleY(1.1);
    }

    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -15px, 0) scaleY(1.05);
    }

    80% {
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0) scaleY(0.95);
    }

    90% {
      transform: translate3d(0, -4px, 0) scaleY(1.02);
    }
  }
}

@media (min-width: 2000px) {
  .title {
    .wide-only {
      display: inline;
    }

    picture:nth-of-type(1) {
      width: 2000px;

      img {
        object-position: left 50%;
      }
    }

    picture:nth-of-type(2) {
      width: calc(100vw - 2000px);

      img {
        object-position: left 50%;
      }
    }
  }
}

@media (max-width: 760px) {
  .title__text {
    font-size: 1.8em;
  }
}

@media (max-width: 460px) {
  .title__text {
    font-size: 1.5em;
  }
}
</style>