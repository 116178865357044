import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css';
import './assets/scss/main.scss'

const app = createApp(App);

// Email de contact découpé en tranche au cas où des robots scannent le code
app.config.globalProperties.telemacEmail = ['contac', 't@te', 'lemac.org'];
// L'URL du backend est une variable d'environnment des fichiers .env
app.config.globalProperties.backendURL = process.env.VUE_APP_BACKEND_URL;
// Id de l'image "contact" de la page contact : variable d'environnment des fichiers .env
app.config.globalProperties.contactImgId = Number(process.env.VUE_APP_CONTACT_IMG_ID);

app.use(store).use(router).mount('#app')
