<template>
  <div class="container long">
    <section id="article-single" v-if="article">
      <article>
        <h1 v-html="article.title.rendered"></h1>
        <p>
          le
          <time :datetime="article.date">{{ getFormattedDate(article.date) }}</time>
        </p>
        <div class="img-main">
          <ImgThumbnail :sizesArray="sizesArray" sizes="(max-width: 460px) 92vw, (max-width: 992px) 84vw, (max-width: 1300px) 77vw, 1028px" :width="imgWidth" :height="imgHeight" />
        </div>
        <div class="article-content" v-html="article.content.rendered"></div>
      </article>
    </section>
    <div class="separator"></div>
    <section v-if="article">
      <CommentList :postId="article.id" />
      <div class="comment-form">
        <p class="comment-title">Écrire un commentaire</p>
        <CommentForm :postId="article.id" />
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import CommentList from "@/components/CommentList.vue";
import CommentForm from "@/components/CommentForm.vue";
import ImgThumbnail from "@/components/ImgThumbnail.vue";
import Prism from "prismjs";

export default {
  name: "Article",
  components: {
    CommentList,
    CommentForm,
    ImgThumbnail,
  },
  async created() {
    this.slug = this.$route.params.slug;
    const url = this.backendURL + "/wp-json/wp/v2/posts?_embed=wp:featuredmedia&slug=" + this.slug;

    const response = await axios.get(url);
    this.article = response.data[0];

    this.sizesArray = this.article._embedded && Object.values(this.article._embedded["wp:featuredmedia"]["0"].media_details.sizes);

    this.imgHeight = this.article._embedded && this.article._embedded["wp:featuredmedia"]["0"].media_details.height;
    this.imgWidth = this.article._embedded && this.article._embedded["wp:featuredmedia"]["0"].media_details.width;

    this.$store.commit("setTitle", {
      title: "Le Blog",
      titleTag: "p",
      titleHTMLTag: this.article.title.rendered, // Contenu de la balise <title>
    });

    this.$nextTick(function () {
      Prism.highlightAll();
    });
  },
  methods: {
    getFormattedDate(wpDate) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedDate = new Date(wpDate).toLocaleDateString("fr-FR", options);

      return formattedDate;
    },
  },
  data() {
    return {
      article: null,
      slug: null,
      sizesArray: null,
      imgWidth: "",
      imgHeight: "",
    };
  },
};
</script>

<style lang="scss">
#article-single article {
  padding: 0 3em;
  img {
    max-width: 100%;
  }
  .article-content {
    margin-top: 3em;
    text-align: left;
  }
  code[class*="language-"],
  pre[class*="language-"] {
    font-size: 15px;
  }

  @media (max-width: 992px) {
    padding: 0;
  }
}

.comment-form .comment-title {
  margin-top: 3em;
  font-weight: bold;
}
</style>