<template>
  <footer>
    <div class="footer-container">
      <p>Ambiance du site :</p>
      <div class="theme-switch-wrapper">
        <label class="theme-switch" for="checkbox">
          <input
            type="checkbox"
            id="checkbox"
            v-model="darkTheme"
            @change="toggleTheme"
          />
          <div class="slider">
            <img
              src="../assets/img/toggle-sun.svg"
              alt="Sun icon"
              class="toggle-sun"
            />
            <img
              src="../assets/img/toggle-moon.svg"
              alt="Moon icon"
              class="toggle-moon"
            />
          </div>
        </label>
      </div>
    </div>
    <p class="footer-text">Graphisme par <a href="https://yemguy.com/">Yemguy</a></p>
  </footer>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
      theme: "", //When this property is empty, the theme is set to the default theme i.e. light mode.
      darkTheme: false,
    };
  },

  methods: {
    toggleTheme() {
      document.documentElement.setAttribute("data-dark-theme", this.darkTheme); // sets the data-theme attribute
      localStorage.setItem("dark-theme", this.darkTheme); // stores theme value on local storage
      console.log(this.darkTheme);
    },
  },
  mounted() {
    
    if (localStorage.getItem('dark-theme')) {
      this.darkTheme = localStorage.getItem('dark-theme');
    }
    
    document.documentElement.setAttribute('data-dark-theme', this.darkTheme);
  }
};
</script>

<style lang="scss">
$background-color-highlight: #f3f3f3;
$toggle-height: 34px;
$toggle-width: 60px;
$toggle-button-size: 26px;
$toggle-disk-color: #dbdada;
$transition-time: 0.4s;

footer {
  position: relative;
  background-color: $footer-bg-color;
  min-height: 8rem;
  margin-top: -4rem;
  width: 100%;
  flex: 1; // taille footer s'adapte si page trop petite
  transition: background-color 1s ease;

  .footer-container {
    //position: relative;
    margin-top: 3.5rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .theme-switch-wrapper {
    display: flex;
    align-items: center;
  }

  .theme-switch {
    display: inline-block;
    position: relative;
    height: $toggle-height;
    width: $toggle-width;
  }

  .slider {
    background: $background-color-highlight;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    cursor: pointer;
    border-radius: $toggle-height;
    transition: $transition-time;
  }

  .slider:before {
    content: "";
    position: absolute;
    left: 4px;
    bottom: 4px;
    background: $toggle-disk-color;
    width: $toggle-button-size;
    height: $toggle-button-size;
    transition: $transition-time;
    border-radius: 100%;
  }

  label {
    margin: 0;
    padding: 0 1px;
  }

  input:checked + .slider:before {
    transform: translateX($toggle-button-size);
  }

  input:checked + .slider .toggle-sun {
    opacity: 0;
    transform: translateX(-4px);
  }

  input:checked + .slider .toggle-moon {
    opacity: 1;
    transform: translateX(0);
  }

  .slider img {
    color: #000000;
    position: absolute;
    transition: opacity 0.2s ease 0s, transform 0.35s ease 0s;
    pointer-events: none;
    width: auto;
  }

  .toggle-sun {
    opacity: 1;
    right: 10px;
    bottom: 9px;
    transform: translateX(0px);
  }

  .toggle-moon {
    opacity: 0;
    left: 9px;
    bottom: 9px;
    transform: translateX(4px);
  }

  .footer-text {
    position: relative;
    margin-top: 0;
    font-size: 1rem;
  }
}
</style>