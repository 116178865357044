<template>
  <Header />
  <main>
    <PageTitle />
    <router-view />
  </main>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "App",
  components: {
    Header,
    PageTitle,
    Footer,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  display: flex; // Astuce pour footer de taille adaptative : https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
  flex-direction: column;
  min-height: 100vh;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
