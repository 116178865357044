<template>
  <div class="comment-list">
    <p class="comment-title">Commentaires</p>
    <p v-if="commentList.length == 0">Aucun commentaire pour le moment !</p>
    <div class="comment" v-for="comment in commentList" :key="comment">
      <p class="comment-author">
        Par <strong>{{ comment.author_name }}</strong>
      </p>
      <p class="comment-time">
        le <time :datetime="comment.date">{{ getFormattedDate(comment.date) }}</time>
      </p>
      <div class="article-content" v-html="comment.content.rendered"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "CommentList",
  props: {
    postId: Number,
  },
  async created() {
    const url = this.backendURL + "/wp-json/wp/v2/comments?order=asc&post=" + this.postId;

    const response = await axios.get(url);
    this.commentList = response.data;
  },
  methods: {
    getFormattedDate(wpDate, showTime = true) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      let formattedDateTime = new Date(wpDate).toLocaleDateString("fr-FR", options);

      if (showTime) {
        options = { hour: "numeric", minute: "numeric" };
        const formattedTime = new Date(wpDate).toLocaleTimeString("fr-FR", options);
        formattedDateTime += " à " + formattedTime;
      }

      return formattedDateTime;
    },
    getThumbnailURL(article) {
      let thumbURL = article._embedded && article._embedded["wp:featuredmedia"]["0"].media_details.sizes["full"].source_url;

      return thumbURL;
    },
  },
  data() {
    return {
      commentList: [],
    };
  },
};
</script>

<style lang="scss">

.comment-list .comment-title {
  font-size: 1.17em;
  font-weight: bold;
  margin-bottom: 2em;
}

.comment-list .comment {
  width: 30em;
  border-radius: 10px;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.5em 1.5em;
  text-align: left;

  &:nth-of-type(2n) {
    background-color: var(--article-card-bg-color-1);
  }

  &:nth-of-type(2n + 1) {
    background-color: var(--article-card-bg-color-2);
  }

  .comment-author {
    margin-bottom: 0.5em;
  }

  .comment-time {
    margin-top: 0;
    color: var(--figcaption-color);
    font-size: 15px;
  }

  @media (max-width: 760px) {
    width: 100%;
}
}
</style>