<template>
    <picture>
      <source srcset="../assets/img/banner_2000x2.webp 2x, ../assets/img/banner_2000.webp" type="image/webp" media="(min-width: 1024px)" />
      <source srcset="../assets/img/banner_2000x2.jpg 2x, ../assets/img/banner_2000.jpg" type="image/jpeg" media="(min-width: 1024px)" />
      <source srcset="../assets/img/banner_1024x2.webp 2x, ../assets/img/banner_1024.webp" type="image/webp" media="(min-width: 768px)" />
      <source srcset="../assets/img/banner_1024x2.jpg 2x, ../assets/img/banner_1024.jpg" type="image/jpeg" media="(min-width: 768px)" />
      <source srcset="../assets/img/banner_768x2.webp 2x, ../assets/img/banner_768.webp" type="image/webp" media="(min-width: 450px)" />
      <source srcset="../assets/img/banner_768x2.jpg 2x, ../assets/img/banner_768.jpg" type="image/jpeg" media="(min-width: 450px)" />
      <source srcset="../assets/img/banner_450x2.webp 2x, ../assets/img/banner_450.webp" type="image/webp" />
      <source srcset="../assets/img/banner_450x2.jpg 2x, ../assets/img/banner_450.jpg" type="image/jpeg" />
      <img src="../assets/img/banner_1024.jpg" srcset="../assets/img/banner_1024x2.jpg 2x" alt="" />
    </picture>
</template>

<script>
export default {
  name: "PageTitle",
}
</script>